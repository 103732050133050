import React from "react";
import classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <p>2013 www.diorakids.ru</p>
    </div>
  );
};

export default Footer;
